exports.components = {
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-about-what-we-do-tsx": () => import("./../../../src/templates/AboutWhatWeDO.tsx" /* webpackChunkName: "component---src-templates-about-what-we-do-tsx" */),
  "component---src-templates-about-who-we-are-tsx": () => import("./../../../src/templates/AboutWhoWeAre.tsx" /* webpackChunkName: "component---src-templates-about-who-we-are-tsx" */),
  "component---src-templates-career-tsx": () => import("./../../../src/templates/Career.tsx" /* webpackChunkName: "component---src-templates-career-tsx" */),
  "component---src-templates-datev-tsx": () => import("./../../../src/templates/Datev.tsx" /* webpackChunkName: "component---src-templates-datev-tsx" */),
  "component---src-templates-demo-tsx": () => import("./../../../src/templates/Demo.tsx" /* webpackChunkName: "component---src-templates-demo-tsx" */),
  "component---src-templates-feature-tsx": () => import("./../../../src/templates/Feature.tsx" /* webpackChunkName: "component---src-templates-feature-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-industries-tsx": () => import("./../../../src/templates/Industries.tsx" /* webpackChunkName: "component---src-templates-industries-tsx" */),
  "component---src-templates-key-account-tsx": () => import("./../../../src/templates/KeyAccount.tsx" /* webpackChunkName: "component---src-templates-key-account-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/Legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-login-ab-tsx": () => import("./../../../src/templates/LoginAb.tsx" /* webpackChunkName: "component---src-templates-login-ab-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/templates/Login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/NotFound.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/Partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-partners-tsx": () => import("./../../../src/templates/Partners.tsx" /* webpackChunkName: "component---src-templates-partners-tsx" */),
  "component---src-templates-pay-tsx": () => import("./../../../src/templates/Pay.tsx" /* webpackChunkName: "component---src-templates-pay-tsx" */),
  "component---src-templates-pos-tsx": () => import("./../../../src/templates/Pos.tsx" /* webpackChunkName: "component---src-templates-pos-tsx" */),
  "component---src-templates-press-tsx": () => import("./../../../src/templates/Press.tsx" /* webpackChunkName: "component---src-templates-press-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/Pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-roi-tsx": () => import("./../../../src/templates/ROI.tsx" /* webpackChunkName: "component---src-templates-roi-tsx" */),
  "component---src-templates-signups-tsx": () => import("./../../../src/templates/Signups.tsx" /* webpackChunkName: "component---src-templates-signups-tsx" */),
  "component---src-templates-ssu-pos-tsx": () => import("./../../../src/templates/SsuPos.tsx" /* webpackChunkName: "component---src-templates-ssu-pos-tsx" */),
  "component---src-templates-ssu-tsx": () => import("./../../../src/templates/Ssu.tsx" /* webpackChunkName: "component---src-templates-ssu-tsx" */),
  "component---src-templates-thanks-pos-tsx": () => import("./../../../src/templates/ThanksPos.tsx" /* webpackChunkName: "component---src-templates-thanks-pos-tsx" */),
  "component---src-templates-thanks-tsx": () => import("./../../../src/templates/Thanks.tsx" /* webpackChunkName: "component---src-templates-thanks-tsx" */),
  "component---src-templates-why-shore-tsx": () => import("./../../../src/templates/WhyShore.tsx" /* webpackChunkName: "component---src-templates-why-shore-tsx" */)
}

