import React from 'react';
import i18next from 'i18next';
import { WrapPageElementNodeArgs } from 'gatsby';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { LocalizedPageContext } from '../../utils/types';

function withI18nTranslation({
  element,
  props,
}: WrapPageElementNodeArgs<any, LocalizedPageContext>) {
  const {
    pageContext: { lang, i18nResources },
  } = props;

  const i18n = i18next
    .createInstance({
      lng: lang,
      fallbackLng: 'en',
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: i18nResources,
    })
    .use(initReactI18next);

  i18n.init();

  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
}

export { withI18nTranslation };
